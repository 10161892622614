@tailwind base;
@tailwind components;
@tailwind utilities;

.ytplayer {
  pointer-events: none;
}
.mail-subscribe::before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background-color: rgb(141 162 251);
  bottom: -10px;
  transform: rotate(45deg);
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.indiana-scroll-container {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.my-masonry-grid {
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}
.language-select {
  outline: none !important;
  border: none !important;
  appearance: none !important;
}
.language-select:focus {
  outline: none !important;
  border: none !important;
}
.language-select::-ms-expand {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}
.ant-popover-inner {
  border-radius: 20px !important;
  padding: 2rem !important;
}

#video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

#video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moving-left {
  transition: transform 0.3s ease;
  transition: 0.2s;
  transform: translateX(0px);
  width: 200px;
  right: -10px;
}
.moving-left:hover {
  transform: translateX(-10px);
  cursor: pointer;
}
